<template>
  <div>
    <TripIndex
      :fstId="`vehicleRides_${getId}`"
      :endpoint="getEndpoint"
      :qso="getEndpointOptions"
      :filterItems="filterOptions"
    />
  </div>
</template>

<script>
import TripIndex from '@/composites/trip/index/Index.vue'
import { useEndpoints } from '@/composables'

export default {
  name: 'ViewVehicleRides',

  components: {
    TripIndex,
  },
  data() {
    return {
      filterOptions: [
        {
          key: 'status',
          type: 'checkbox',
          input: [
            { label: 'Completed', value: 'C' },
            { text: 'Force completed By System', value: 'FCS' },
            { text: 'Force Completed by Admin', value: 'FC' },
            { text: 'Reserved', value: 'R' },
            { text: 'Reservation Timed Out', value: 'T' },
            { text: 'Starting Trip', value: 'U' },
            { text: 'On Trip', value: 'O' },
            // { text: 'On Pay', value: 'P' },
            // { text: 'On Feedback', value: 'F' },
            { text: 'Reservation Cancelled', value: 'A' },
            { text: 'Forced Cancelled', value: 'FA' },
          ],
          title: 'Status',
        },
        {
          key: 'trip_type',
          type: 'checkbox',
          input: [
            { text: 'Individual', value: 'individual_trips' },
            { text: 'Group', value: 'group_trips' },
            { text: 'Paused', value: 'paused_trips' },
            { text: 'Unpaused', value: 'unpaused_trips' },
          ],
          title: 'Trip Type',
        },

        {
          key: 'tripCost',
          type: 'range',
          input: {
            value: [0, 0], // initial value of model -> [min, max]
            unit: this.currencySymbol,
            max: 1000,
            minQs: 'min_cost', // query string for min value
            maxQs: 'max_cost', // query string for max value
          },
          title: 'Trip Costs',
        },
        {
          key: 'tripDuration',
          type: 'range',
          input: {
            value: [0, 0], // initial value of model -> [min, max]
            unit: ' Min',
            max: 500,
            minQs: 'min_duration_mins', // query string for min value
            maxQs: 'max_duration_mins', // query string for max value
          },
          title: 'Trip Duration',
        },
      ],
    }
  },

  computed: {
    getId() {
      return this.$route.params.id
    },
    currencySymbol() {
      return this.$org.default_currency.symbol
    },
    getEndpoint() {
      return useEndpoints.trip.index()
    },
    getEndpointOptions() {
      return {
        append: `&bike=${this.$route.params.id}`,
        prepend: `?`,
      }
    },
  },
}
</script>

<style lang="scss"></style>
